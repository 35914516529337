import { toast } from 'react-toastify';
import { logout } from './login';

export default function errorHandler(error) {

    let errorType = 500;
    let msg;
    let msgFinal = true;

    if (error) {

        if (error.status >= 400 && error.status <= 499) {

            errorType = 400;

            msg = 'Ocorreu um problema ao processar a sua solicitação';

            if (error.data && Array.isArray(error.data)) {
                msgFinal = false;
            }
            if(error.status === 401) {
                msg = 'Sessão expirou'
                logout();
            }
            if(error.status === 403) {
                msg = 'Sem permissão'
                window.location.href = '/sempermissao'
            }

        } else {
            msg = 'Problema ao processar serviço remoto. Tente novamente.';
        }

    } else {
        msg = 'Sem conexão com o servidor';
        logout();
    }

    if (msgFinal) {
        if(errorType === 400) {
            toast.warn(msg)
        } else {
            toast.error(msg)
        }
    } else {
        exibeErrosApi(error, errorType);
    }

}


const exibeErrosApi = (error, errorType) => {
    error.data.forEach(e => {
        if (e.message) {
            if(errorType === 400) {
                toast.warn(e.message)
            } else {
                toast.error(e.message)
            }
        }
    });
}