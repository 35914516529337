import menuCreator from '../../Handlers/MenuCreatorHandler';

const itens = [
    { label: 'Recebimento Matéria Prima', icon: 'fas fa-glass-whiskey', auth: 'LABORATORIO_RECEBIMENTO_MATERIA_PRIMA', url: '/laboratorio/recebimentomateriaprima' },
    { label: 'Blend', icon: 'fa-solid fa-blender', auth: 'LABORATORIO_FORMULACAO', url: '/laboratorio/blend' },
    { label: 'Formulação', icon: 'fas fa-mortar-pestle', auth: 'LABORATORIO_FORMULACAO', url: '/laboratorio/formulacao' }
]

const laboratorioMenuItens = () => {
    return menuCreator(itens, 'Laboratório', 'fas fa-flask')
}

export default laboratorioMenuItens;