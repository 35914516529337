import React, { lazy } from "react";
import { Routes, Route } from "react-router-dom";

// RecebimentoMateriaPrima
const RecebimentoMateriaPrimaLista = lazy(() => import('../pages/Laboratorio/RecebimentoMateriaPrima/RecebimentoMateriaPrimaLista'));
const RecebimentoMateriaPrimaCadastro = lazy(() => import('../pages/Laboratorio/RecebimentoMateriaPrima/RecebimentoMateriaPrimaCadastro'));
// Blend
const CadastroBlend = lazy(() => import('../pages/Laboratorio/Blend/CadastroBlende'));
const ListaBlend = lazy(() => import('../pages/Laboratorio/Blend/ListaBlend'));
//formulacao
const FormulacaoLista = lazy(() => import('../pages/Laboratorio/Formulacao/Formulacao'));
const FormulacaoCadastro = lazy(() => import('../pages/Laboratorio/Formulacao/FormulacaoCadastro'));

export default function LaboratorioRoutes() {
    return (
        <Routes>
            {/* RecebimentoMateriaPrima */}
            <Route path="/laboratorio/recebimentomateriaprima" exact element={<RecebimentoMateriaPrimaLista />} />
            <Route path="/laboratorio/recebimentomateriaprima/novo" exact element={<RecebimentoMateriaPrimaCadastro />} />
            <Route path="/laboratorio/recebimentomateriaprima/:id" exact element={<RecebimentoMateriaPrimaCadastro />} />
            {/* Blend */}
            <Route path="/laboratorio/blend" exact element={<ListaBlend />} />
            <Route path="/laboratorio/blend/novo" exact element={<CadastroBlend />} />
            <Route path="/laboratorio/blend/:id" exact element={<CadastroBlend />} />

            {/* Formulacao */}
            <Route path="/laboratorio/formulacao" exact element={<FormulacaoLista />} />
            <Route path="/laboratorio/formulacao/:id" exact element={<FormulacaoCadastro />} />
            <Route path="/laboratorio/formulacao/novo" exact element={<FormulacaoCadastro />} />

        </Routes>
    )
}