import axios from 'axios';
import errorHandler from './errorHandler';
import { baseUrl } from './variables/url';

export const api = () => {
    const token = localStorage.getItem('token');
    return axios.create({
        baseURL: baseUrl,
        headers: { 'Authorization': `bearer ${token}` }
    });
}

export const apiGet = (url, conf = null) => {
    return api().get(url, conf).then(response => {
        return response.data;
    }).catch(error => {
        errorHandler(error.response);
    });

}

export const apiPost = (url, data, conf = null) => {
    return api().post(url, data, conf).then(response => {
        return response.data;
    }).catch(error => {
        errorHandler(error.response);
    });
}
export const apiPut = (url, data, conf = null) => {
    return api().put(url, data, conf).then(response => {
        return response.data;
    }).catch(error => {
        errorHandler(error.response);
    });
} 

export const apiDelete = (url, conf = null) => {
    return new Promise((resolve, reject) => {
        api().delete(url, conf).then(response => {
            resolve(response.data);
        }).catch(error => {
            errorHandler(error.response);
        });
    });
}

export default api;